import React from 'react';
import 'pages/contactUs/_contactUs.scss';
import {hooks, labels, schemas} from 'common';
import {Form} from 'react-bootstrap';
import {AnimatedButton1} from 'components';
import {useFormik} from 'formik';
import {api} from 'api';
import {toast} from 'react-toastify';

function ContactUs(props) {
    const formik = useFormik({
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            message: '',
        },
        validationSchema: schemas.CONTACT_US,
        onSubmit: async (values) => {
            try {
                await api.contactUs({data: values});
                toast.success('Message sent successfully');
                formik.resetForm();
            }catch (e) {
                toast.error('Unable to send message.');
            }
        },
    });

    hooks.useScrollToTopInstant();
    return (
        <div className={'contact-us'}>
            <h1 className={'content-header'}>
                {labels.CONTACT_US}
            </h1>
            <div className={'content-detail'}>
                <Form className='contact-form' onSubmit={formik.handleSubmit}>
                    <span className='form-head'>Contact Information</span>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control name="email" type="email" placeholder="Email"
                                      value={formik.values.email} onChange={formik.handleChange}
                                      isInvalid={formik.touched.email && !!formik.errors.email}
                        />
                        <Form.Text className="text-danger">
                            {formik.touched.email && formik.errors.email}
                        </Form.Text>
                    </Form.Group>
                    <div className='form-row'>
                        <Form.Group>
                            <Form.Control name="first_name" type="text" placeholder="First Name"
                                          value={formik.values.first_name}
                                          onChange={formik.handleChange}
                                          isInvalid={formik.touched.first_name && !!formik.errors.first_name}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.first_name && formik.errors.first_name}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control name="last_name" type="text" placeholder="Last Name"
                                          value={formik.values.last_name}
                                          onChange={formik.handleChange}
                                          isInvalid={formik.touched.last_name && !!formik.errors.last_name}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.last_name && formik.errors.last_name}
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <span className='form-head'>Message</span>
                    <Form.Group>
                        <Form.Control name="message" as="textarea" rows={4} placeholder="Message..."
                                      value={formik.values.message} onChange={formik.handleChange}
                                      isInvalid={formik.touched.message && !!formik.errors.message}
                        />
                        <Form.Text className="text-danger">
                            {formik.touched.message && formik.errors.message}
                        </Form.Text>
                    </Form.Group>
                    <AnimatedButton1 type="submit" title='Submit'/>
                </Form>
            </div>
        </div>
    );
}

export default ContactUs;