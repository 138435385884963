import React, {useEffect} from 'react';
import {hooks, labels, routes} from 'common';
import {BgSection, ProductSection} from 'components';
import 'pages/home/_home.scss';
import {useDispatch, useSelector} from 'react-redux';
import {generalExtraActions} from 'reduxStore/actions';
import {Banner, SmallBanner} from 'assets';

function Home() {
    const dispatch = useDispatch()
    const {topProducts} = useSelector(state => state.general);

    useEffect(() => {
        dispatch(generalExtraActions.topProducts());
    }, [dispatch]);

    hooks.useScrollToTopInstant();
    return (
        <div className={'home'}>
            <BgSection
                largeText={labels.NEW_ARRIVAL}
                link={routes.SHOP}
                btnLabel={labels.SHOP_COLLECTION}
                bgImg={Banner}
                bgSmallImg={SmallBanner}
            />
            <ProductSection heading={labels.BEST_SELLER} viewAllLink={routes.SHOP} products={topProducts}/>
        </div>
    );
}

export default Home;
