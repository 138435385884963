import {useEffect, useState} from 'react';
import {labels, localStore} from 'common';

export const hooks = {
    useToken: () => {
        const [isUserLoggedIn, setTokens] = useState(localStore.isUserLoggedIn());

        const saveTokens = (tokens) => {
            if (tokens) {
                localStore.storeTokens(tokens);
                setTokens(tokens.access);
            }
        };

        return {
            setTokens: saveTokens, isUserLoggedIn,
        };
    },
    usePageTitle: (title) => {
        useEffect(() => {
            document.title = `${labels.APP} - ${title}`;
        }, [title]);
        return null;
    },
    useScrollToTop: () => {
        useEffect(() => {
            window.scrollTo({
                top: 0, left: 0, behavior: 'smooth',
            });
        }, []);
    },
    useScrollToTopInstant: () => {
        useEffect(() => {
            window.scrollTo({
                top: 0, left: 0, behavior: 'instant',
            });
        }, []);
    },
};
