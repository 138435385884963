import * as Yup from 'yup';
import {globals} from 'common/constants';

export const schemas = {
    CHECKOUT: Yup.object().shape({
        email: Yup.string().email('Enter a valid email address').required('This field is required'),
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string().required('This field is required'),
        address: Yup.string().required('This field is required'),
        city: Yup.string().required('This field is required'),
        postal_code: Yup.string().required('This field is required'),
        phone_number: Yup.string().matches(globals.PAKISTAN_PHONE_NUMBER_REGEX, 'Phone number is not valid (03221234567 or +923221234567)').required('This field is required'),
    }),
    CONTACT_US: Yup.object().shape({
        email: Yup.string().email('Enter a valid email address').required('This field is required'),
        first_name: Yup.string().required('This field is required'),
        last_name: Yup.string().required('This field is required'),
        message: Yup.string().required('This field is required'),
    }),
};
