export const labels = {
    HOME: 'Home',
    SALE: 'Sale',
    NEW_ARRIVAL: 'New Arrival',
    ABOUT_US: 'About Us',
    INFORMATION: 'Information',
    FAQS: 'FAQs',
    CONTACT_US: 'Contact Us',
    PRIVACY_POLICY: 'Privacy Policy',
    SHIPPING_POLICY: 'Shipping Policy',
    RETURN_N_EXCHANGE_POLICY: 'Return & Exchange Policy',
    MEN: 'Men',
    WOMEN: 'Women',
    ACCESSORIES: 'Accessories',
    CONTACT: 'Contact',
    NEWSLETTER: 'Newsletter',
    SUBSCRIBE: 'Subscribe',
    SHOP: 'Shop',
    WAREHOUSE: 'Warehouse',
    HOURS: 'Hours',
    PHONE: 'Phone',
    EMAIL: 'E-mail',
    SHOP_COLLECTION: 'Shop Collection',
    VIEW_ALL: 'View All',
    BEST_SELLER: 'Best Seller',
    WOMEN_COLLECTION: 'Women Collection',
    SHOP_NOW: 'Shop Now',
    SELECT_SIZE: 'Select Size',
    ADD_TO_CART: 'Add to Cart',
    WRITE_REVIEW: 'Write Review',
    CANCEL: 'Cancel',
    SEND: 'Send',
    CART: 'Cart',
    CHECKOUT: 'Checkout',
    FREE_DELIVERY: 'Free Delivery',
};

export const sentences = {
    NEWSLETTER: 'Sign up to our newsletter to receive exclusive offers.'
};

export const siteDate = {
    BRAND_NAME: 'TheMagicLine',
    WAREHOUSE: 'Allah Ho Industrial Estate, Street No. 5, Near Plasto Factory, Lahore, Pakistan.',
    HOURS: 'Monday-Friday (10AM-6PM)',
    PHONE: '+92 332 0445909',
    EMAIL: 'themagiclinestore@gmail.com',
    COUNTRY: 'Pakistan',
    INSTAGRAM: 'https://www.instagram.com/the_magic_line/'
};

export const globals = {
    PAKISTAN_PHONE_NUMBER_REGEX: /^(\+92|0)\d{10}$/,
    MAX_PRODUCT_QUANTITY: 5,
    MIN_PRODUCT_QUANTITY: 1,
    TOAST_TIMER: 3000,
    BG_WIDTH_BREAKPOINT: 600,
}