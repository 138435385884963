import React from 'react';
import {AnimatedButton1, CartItem} from 'components';
import 'components/cart/_cart.scss';
import {labels} from 'common/constants';
import {RxCross1} from 'react-icons/rx';
import {useDispatch, useSelector} from 'react-redux';
import {utils} from 'common';
import {generalExtraActions} from 'reduxStore/actions';
import {toast} from 'react-toastify';

function Cart({show, setShow}) {
    const dispatch = useDispatch();
    const {cartProducts} = useSelector(state => state.cart);
    const {order} = useSelector(state => state.general);

    const hideCart = () => {
        setShow(false);
    };

    const handleCheckout = async () => {
        if(Object.keys(cartProducts).length === 0) {
            return;
        }
        if (order) {
            dispatch(generalExtraActions.orderUpdate(
                {id: order.id, data: {order_products: utils.createOrderProducts(cartProducts)}}
            )).then((response) => {
                if (response?.type === 'general/orderUpdate/fulfilled') {
                    window.location.pathname = '/checkout';
                }
            });
        } else {
            dispatch(generalExtraActions.orderCreate(
                {order_products: utils.createOrderProducts(cartProducts)}
            )).then((response) => {
                if (response?.type === 'general/orderCreate/fulfilled') {
                    window.location.pathname = '/checkout';
                }
            });
        }
    };

    return (
        <div className={`cart-wrapper ${show && "show-cart"}`}>
            <div onClick={hideCart} className='cart-overlay'></div>
            <div className={`cart ${show && "show-cart"}`}>
                <div className="cart-header">
                    <span>{labels.CART}</span>
                    <button onClick={hideCart}>
                        <RxCross1 size={20}/>
                    </button>
                </div>
                <div className='cart-content'>
                    {Object.keys(cartProducts).length === 0 && <p>
                        Your cart is empty
                    </p>}
                    {Object.keys(cartProducts).map((productId, index) => {
                        return <CartItem productId={productId} cartProduct={cartProducts[productId]}/>
                    })}
                </div>

                <div className='cart-footer'>
                    <span className='help-text'>
                        Taxes and shipping calculated at checkout
                    </span>
                    <AnimatedButton1 onClick={handleCheckout}
                                     title={`${labels.CHECKOUT} - ${utils.calculateTotalPrice(cartProducts)}`}/>
                </div>
            </div>
        </div>
    );
}

export default Cart;