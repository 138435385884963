import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'api';

const generalExtraActions = {
  topProducts: createAsyncThunk('general/topProducts', api.topProducts),
  allProducts: createAsyncThunk('general/allProducts', api.allProducts),
  orderCreate: createAsyncThunk('general/orderCreate', api.orderCreate),
  orderUpdate: createAsyncThunk('general/orderUpdate', api.orderUpdate),
};

export default generalExtraActions;
