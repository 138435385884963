import React from 'react';
import {AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai';
import {FormControl} from 'react-bootstrap';
import {RxCross1} from 'react-icons/rx';
import 'components/checkoutItem/_checkoutItem.scss';
import {utils} from 'common';

function CheckoutItem({orderProduct}) {
    const {product, quantity} = orderProduct;
    return (
        <div className='checkout-item'>
            <div className='item-image'>
                <img src={product?.image_urls[0]}/>
            </div>
            <div className='item-detail'>
                <span className='item-name'>{product?.name}</span>
                <span className='item-price'>{utils.formatPrice(product?.price)}</span>
                {/*<span className='item-variant'></span>*/}
                <div className='item-quantity'>
                    Quantity: {quantity}
                </div>
            </div>
            <div className='item-total'>
                <div className='item-quantity'>
                    {utils.formatPrice(product?.price && quantity ? product?.price * quantity : 0)}
                </div>
            </div>
        </div>
    );
}

export default CheckoutItem;
