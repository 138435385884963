import React, {useEffect} from 'react';
import {ProductCard} from 'components';
import 'pages/shop/_shop.scss';
import {useDispatch, useSelector} from 'react-redux';
import {generalExtraActions} from 'reduxStore/actions';
import {hooks} from 'common';

function Shop() {
    const dispatch = useDispatch()
    const {allProducts} = useSelector(state => state.general);

    useEffect(() => {
        dispatch(generalExtraActions.allProducts());
    }, [dispatch]);

    hooks.useScrollToTopInstant();
    return (
        <div className={'shop'}>
            <div className={'shop-products'}>
                {allProducts?.results?.map((product, index) => {
                    return <ProductCard key={index} product={product}/>
                })}
            </div>
        </div>
    );
}

export default Shop;
