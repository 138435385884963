import {apiUtils, endpoints} from 'api';

export const api = {
    login: async (data) => {
        return await apiUtils.post(endpoints.LOGIN, data, false);
    },
    topProducts: async () => {
        return await apiUtils.get(endpoints.TOP_PRODUCTS, {}, false);
    },
    allProducts: async () => {
        return await apiUtils.get(endpoints.ALL_PRODUCTS, {}, false);
    },
    productDetail: async (id) => {
        return await apiUtils.get(endpoints.PRODUCT_DETAIL(id), {}, false);
    },
    orderCreate: async (data) => {
        return await apiUtils.post(endpoints.ORDER_CREATE, data, false);
    },
    orderUpdate: async ({id, data}) => {
        return await apiUtils.patch(endpoints.ORDER_UPDATE(id), data, false);
    },
    orderCheckout: async ({id, data}) => {
        return await apiUtils.patch(endpoints.ORDER_CHECKOUT(id), data, false, false);
    },
    contactUs: async ({data}) => {
        return await apiUtils.post(endpoints.CONTACT_US, data, false, false);
    },
};
