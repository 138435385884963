import React from 'react';
import 'components/customRating/_customRating.scss';
import {Rating} from 'react-simple-star-rating';

function CustomRating({value, size = 16, showValue = true, style}) {
    return (
        <div className={'custom-rating'} style={style}>
            <Rating
                readonly={true}
                size={size}
                fillColor={'black'}
                initialValue={value}
                allowFraction={true}
            />
            {showValue && <span className={'rating-value'}>{value}</span>}
        </div>
    );
}

export default CustomRating;