import React, {useState} from 'react';
import {Button, Collapse} from 'react-bootstrap';
import 'components/customCollapse/_customCollapse.scss';
import {AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai';

function CustomCollapse({title, description, defaultOpen=false}) {
    const [open, setOpen] = useState(defaultOpen);

    return (
        <div className="custom-collapse d-flex flex-column">
            <Button
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="collapse-btn d-flex justify-content-between align-items-center"
            >
                {title}
                {open ?  <AiOutlineMinus /> : <AiOutlinePlus />}
            </Button>
            <Collapse in={open}>
                <div id="example-collapse-text">
                    {description}
                </div>
            </Collapse>
        </div>
    );
}

export default CustomCollapse;