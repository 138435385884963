import React from 'react';
import {AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai';
import {FormControl} from 'react-bootstrap';
import {RxCross1} from 'react-icons/rx';
import 'components/cartItem/_cartItem.scss';
import {utils} from 'common';
import {useDispatch} from 'react-redux';
import {cartActions} from 'reduxStore/reducers/cartSlice';

function CartItem({productId, cartProduct}) {
    const dispatch = useDispatch();
    const handleQuantityIncrease = () => {
        dispatch(cartActions.increaseProductQuantity(productId))
    };

    const handleQuantityDecrease = () => {
        dispatch(cartActions.decreaseProductQuantity(productId))
    };

    const handleRemoveProduct = () => {
        dispatch(cartActions.removeProduct(productId))
    };

    return (
        <div className='cart-item'>
            <div className='item-image'>
                <img src={cartProduct?.image_url}/>
            </div>
            <div className='item-detail'>
                <span className='item-name'>{cartProduct?.name}</span>
                <span className='item-price'>{utils.formatPrice(cartProduct?.price)}</span>
                {/*<span className='item-variant'></span>*/}
                <div className='item-quantity'>
                    <button>
                        <AiOutlineMinus onClick={handleQuantityDecrease}/>
                    </button>
                    <FormControl
                        type='number'
                        min={1}
                        max={5}
                        value={cartProduct?.quantity}
                        readOnly
                    />
                    <button>
                        <AiOutlinePlus onClick={handleQuantityIncrease}/>
                    </button>
                </div>
            </div>
            <div className='item-cancel'>
                <button>
                    <RxCross1 size={16} onClick={handleRemoveProduct}/>
                </button>
            </div>
        </div>
    );
}

export default CartItem;
