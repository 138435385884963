import {createSlice, isAnyOf} from '@reduxjs/toolkit';
import {generalExtraActions} from 'reduxStore/actions';
import {localStore} from 'common';

const INITIAL_STATE = {
    topProducts: [],
    topProductsLoading: false,
    topProductsError: null,
    allProducts: {},
    allProductsLoading: false,
    allProductsError: null,
    order: localStore.getOrder(),
    orderLoading: false,
    orderError: null,
};

export const generalSlice = createSlice({
    name: 'general',
    initialState: INITIAL_STATE,
    reducers: {
        reset: () => INITIAL_STATE,
        resetOrder: (state) => {
            state.order = null;
            state.orderLoading = false;
            state.orderError = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(generalExtraActions.topProducts.fulfilled, (state, {payload}) => {
            state.topProducts = payload;
            state.topProductsLoading = false;
            state.topProductsError = null;
        });
        builder.addCase(generalExtraActions.topProducts.pending, (state, {payload}) => {
            state.topProducts = [];
            state.topProductsLoading = true;
            state.topProductsError = null;
        });
        builder.addCase(generalExtraActions.topProducts.rejected, (state, {payload}) => {
            state.topProducts = [];
            state.topProductsLoading = false;
            state.topProductsError = payload;
        });
        builder.addCase(generalExtraActions.allProducts.fulfilled, (state, {payload}) => {
            state.allProducts = payload;
            state.allProductsLoading = false;
            state.allProductsError = null;
        });
        builder.addCase(generalExtraActions.allProducts.pending, (state, {payload}) => {
            state.allProducts = {};
            state.allProductsLoading = true;
            state.allProductsError = null;
        });
        builder.addCase(generalExtraActions.allProducts.rejected, (state, {payload}) => {
            state.allProducts = {};
            state.allProductsLoading = false;
            state.allProductsError = payload;
        });
        builder.addCase(generalExtraActions.orderCreate.fulfilled, (state, {payload}) => {
            state.order = payload;
            state.orderLoading = false;
            state.orderError = null;
            localStore.storeOrder(payload);
        });
        builder.addCase(generalExtraActions.orderCreate.pending, (state, {payload}) => {
            state.order = null;
            state.orderLoading = true;
            state.orderError = null;
        });
        builder.addCase(generalExtraActions.orderCreate.rejected, (state, {payload}) => {
            state.order = null;
            state.orderLoading = false;
            state.orderError = payload;
            localStore.storeOrder(null);
        });
        builder.addCase(generalExtraActions.orderUpdate.fulfilled, (state, {payload}) => {
            state.order = payload;
            state.orderLoading = false;
            state.orderError = null;
            localStore.storeOrder(payload);
        });
        builder.addCase(generalExtraActions.orderUpdate.pending, (state, {payload}) => {
            state.order = null;
            state.orderLoading = true;
            state.orderError = null;
        });
        builder.addCase(generalExtraActions.orderUpdate.rejected, (state, {payload}) => {
            state.order = null;
            state.orderLoading = false;
            state.orderError = payload;
            localStore.storeOrder(null);
        });

        // builder.addMatcher(
        //     isAnyOf(
        //         generalExtraActions.shifts.pending,
        //     ),
        //     (state, {payload}) => {
        //         state.loading = true;
        //         state.error = null;
        //     }
        // );
        // builder.addMatcher(
        //     isAnyOf(
        //         generalExtraActions.shifts.rejected,
        //     ),
        //     (state, {error}) => {
        //         state.loading = false;
        //         state.error = error;
        //     }
        // );
    },
});

export const {actions: generalActions, reducer} = generalSlice;
export default reducer;
