import React from 'react';
import {labels} from 'common/constants';
import {Link} from 'react-router-dom';
import 'components/productSection/_productSection.scss';
import {ProductCard} from 'components';


function ProductSection({products, heading, viewAllLink}) {
    return (
        <div className={'product-section'}>
            <div className={'content'}>
                <div className={'content-header'}>
                    {heading && <span className={'heading'}>{heading}</span>}
                    {viewAllLink && <Link className={'view'} to={viewAllLink}>{labels.VIEW_ALL}</Link>}
                </div>
                <div className={'products'}>
                    {products.map((product, index) => {
                        return <ProductCard key={index} product={product}/>
                    })}
                </div>
            </div>
        </div>
    );
}

export default ProductSection;