import React from 'react';
import {CustomRating} from 'components';
import 'components/product/_productCard.scss';
import {utils} from 'common';
import {useNavigate} from 'react-router-dom';

function ProductCard({product}) {
    const navigate = useNavigate();
    return (
        <div className={'product-card'} onClick={() => navigate(`/detail/${product?.id}`)}>
            <div className={'pic-section'}>
                {product?.image_urls.length > 0 && <img className={'img-top'} src={product?.image_urls[0]} alt={''}/>}
                {product?.image_urls.length > 1 && <img className={'img-hover'} src={product?.image_urls[1]} alt={''}/>}
            </div>
            <div className={'detail-section'}>
                <CustomRating value={5}/>
                <span className={'name'}>{product?.name}</span>
                <span className={'price'}>{utils.formatPrice(product?.price)}</span>
            </div>
        </div>
    );
}

export default ProductCard;