import React from 'react';
import 'pages/exchangePolicy/_exchangePolicy.scss';
import {hooks, labels} from 'common';

function ExchangePolicy() {
    hooks.useScrollToTopInstant();
    return (
        <div className={'exchange-policy'}>
            <h1 className={'content-header'}>
                {labels.RETURN_N_EXCHANGE_POLICY}
            </h1>
            <div className={'content-detail'}>
                <h4>
                    1. Eligibility for Exchange:
                </h4>
                <p>
                    <b>Damaged Package:</b>
                    <br/>
                    If the package containing your hair oil product arrives visibly damaged, please
                    refuse to accept the delivery. If you have already accepted the delivery, please contact us
                    immediately to arrange for an exchange.
                    <br/>
                    <b>Product Defect:</b>
                    <br/>
                    If you find any defect in the product itself upon opening the package, such as leakage, broken seal,
                    or any other issue affecting its quality, please contact us within 2 days of receiving the
                    order to initiate the exchange process.
                </p>
                <p>
                    <h4>
                        2. Exchange Procedure:
                    </h4>
                    <ul>
                        <li>
                            To initiate an exchange, please contact our customer service team with your order details
                            and a description of the issue.
                        </li>
                        <li>
                            Our customer service team will guide you through the exchange process, which may include
                            providing photographic evidence of the damage or defect.
                        </li>
                        <li>
                            Once the exchange request is approved, we will provide instructions on returning the damaged
                            or defective product.Once the exchange request is approved, we will provide instructions on
                            returning the damaged or defective product.
                        </li>
                    </ul>
                </p>
                <p>
                    <h4>
                        3. Conditions for Exchange:
                    </h4>
                    <ul>
                        <li>
                            The product must be unused and in its original packaging.
                        </li>
                        <li>
                            Exchange requests must be initiated within [number] days of receiving the order.
                        </li>
                        <li>
                            We reserve the right to refuse an exchange if the product shows signs of misuse or damage
                            caused by the customer.
                        </li>
                    </ul>
                </p>
                <p>
                    <h4>
                        4. Replacement Product:
                    </h4>
                    <ul>
                        <li>
                            Upon receiving the returned item and verifying the damage or defect, we will promptly
                            dispatch a replacement product to you at no additional cost.
                        </li>
                        <li>
                            If the exact product is not available, we may offer a suitable alternative or provide a
                            refund, as per your preference.
                        </li>
                    </ul>
                </p>
                <p>
                    <h4>
                        5. Shipping Costs:
                    </h4>
                    <ul>
                        <li>
                            We will cover the shipping costs for returning the damaged or defective product and sending
                            out the replacement.
                        </li>
                        <li>
                            Please follow the instructions provided by our customer service team for returning the item
                            to ensure a smooth exchange process.
                        </li>
                    </ul>
                </p>
                <p>
                    <h4>
                        6. Contact Us:
                    </h4>
                    <ul>
                        <li>
                            If you have any questions or concerns about our exchange policy, please don't hesitate to
                            reach out to our customer service team.
                        </li>
                    </ul>
                </p>
            </div>
        </div>
    )
        ;
}

export default ExchangePolicy;
