import React from 'react';
import {Form} from 'react-bootstrap';
import 'pages/checkout/_checkout.scss';
import {AnimatedButton1, CheckoutItem} from 'components';
import {schemas} from 'common/schemas';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {hooks, labels, localStore, routes, utils} from 'common';
import {toast} from 'react-toastify';
import {api} from 'api';
import {useNavigate} from 'react-router-dom';
import {generalActions} from 'reduxStore/reducers/generalSlice';
import {cartActions} from 'reduxStore/reducers/cartSlice';

function Checkout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {order} = useSelector(state => state.general);
    const formik = useFormik({
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            address: '',
            city: '',
            postal_code: '',
            phone_number: '',
        },
        validationSchema: schemas.CHECKOUT,
        onSubmit: async (values) => {
            if (order) {
                try {
                    await api.orderCheckout({id: order.id, data: {customer: values}});
                    localStore.removeCartProducts();
                    localStore.removeOrder();
                    dispatch(generalActions.resetOrder());
                    dispatch(cartActions.reset());
                    navigate(routes.SHOP);
                    toast.success('Order placed successfully.')
                } catch (e) {
                    toast.error('Unable to place order.');
                    console.log(e);
                }
            }
        },
    });

    hooks.useScrollToTopInstant();
    return (
        <div className='checkout'>
            <div className='checkout-contact'>
                <Form className='contact-form' onSubmit={formik.handleSubmit}>
                    <span className='form-head'>Contact Information</span>
                    <Form.Group className="mb-2" controlId="formBasicEmail">
                        <Form.Control name="email" type="email" placeholder="Email"
                                      value={formik.values.email} onChange={formik.handleChange}
                                      isInvalid={formik.touched.email && !!formik.errors.email}
                        />
                        <Form.Text className="text-danger">
                            {formik.touched.email && formik.errors.email}
                        </Form.Text>
                    </Form.Group>
                    <span className='form-head'>Delivery Information</span>
                    <div className='form-row'>
                        <Form.Group>
                            <Form.Control name="first_name" type="text" placeholder="First Name"
                                          value={formik.values.first_name}
                                          onChange={formik.handleChange}
                                          isInvalid={formik.touched.first_name && !!formik.errors.first_name}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.first_name && formik.errors.first_name}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control name="last_name" type="text" placeholder="Last Name"
                                          value={formik.values.last_name}
                                          onChange={formik.handleChange}
                                          isInvalid={formik.touched.last_name && !!formik.errors.last_name}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.last_name && formik.errors.last_name}
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <Form.Group>
                        <Form.Control name="address" type="text" placeholder="Address" value={formik.values.address}
                                      onChange={formik.handleChange}
                                      isInvalid={formik.touched.address && !!formik.errors.address}
                        />
                        <Form.Text className="text-danger">
                            {formik.touched.address && formik.errors.address}
                        </Form.Text>
                    </Form.Group>
                    <div className='form-row'>
                        <Form.Group>
                            <Form.Control name="city" type="text" placeholder="City" value={formik.values.city}
                                          onChange={formik.handleChange}
                                          isInvalid={formik.touched.city && !!formik.errors.city}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.city && formik.errors.city}
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control name="postal_code" type="text" placeholder="Postal Code"
                                          value={formik.values.postal_code}
                                          onChange={formik.handleChange}
                                          isInvalid={formik.touched.postal_code && !!formik.errors.postal_code}
                            />
                            <Form.Text className="text-danger">
                                {formik.touched.postal_code && formik.errors.postal_code}
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <Form.Group>
                        <Form.Control name="phone_number" type="tel" placeholder="Phone Number"
                                      value={formik.values.phone_number} onChange={formik.handleChange}
                                      isInvalid={formik.touched.phone_number && !!formik.errors.phone_number}
                        />
                        <Form.Text className="text-danger">
                            {formik.touched.phone_number && formik.errors.phone_number}
                        </Form.Text>
                        <Form.Text as='p'>In case we need to contact you about your order.</Form.Text>
                    </Form.Group>
                    <Form.Group className='cash-on-delivery d-flex justify-content-start align-items-center gap-2'>
                        <Form.Check type="radio" checked readOnly/>
                        <Form.Label>Cash on Delivery</Form.Label>
                    </Form.Group>
                    <AnimatedButton1 type="submit" title='Continue'/>
                </Form>
            </div>
            <div className='checkout-detail'>
                <div className='checkout-items'>
                    {order?.order_products?.map((orderProduct, index) => {
                        return <CheckoutItem key={index} orderProduct={orderProduct}/>
                    })}
                </div>
                <div className='calculation'>
                    <div className='calculation-minor'>
                        <span className='cal-head'>Subtotal</span>
                        <span className='cal-val'>{utils.formatPrice(order?.subtotal)}</span>
                    </div>
                    <div className='calculation-minor'>
                        <span className='cal-head'>Shipping</span>
                        <span className='cal-val'>
                            {order?.delivery_charges === 0 ? labels.FREE_DELIVERY : utils.formatPrice(order?.delivery_charges)}
                        </span>
                    </div>
                    <div className='calculation-major'>
                        <span className='cal-head'>Total</span>
                        <span className='cal-val'>{utils.formatPrice(order?.total)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
