import {errorsCodes, localStore, utils} from 'common';
import {endpoints} from 'api/endpoints';
import axios from 'axios';

export const refreshAccessToken = async () => {
    try {
        return (await axios.post(endpoints.REFRESH_TOKEN, {refresh: localStore.getRefreshToken()})).data;
    } catch (error) {
        if (error?.response?.status === errorsCodes.UNAUTHORIZED_CODE) {
            localStore.clearLocalStorge();
            window.location.href = window.origin;
        } else {
            throw error;
        }
    }
};

export const handleError = async (error, callback, url, data, withToken, showToast = true) => {
    if (withToken && error.response?.status === errorsCodes.UNAUTHORIZED_CODE) {
        localStore.storeAccessToken((await refreshAccessToken()).access);
        return await callback(url, data, withToken);
    }
    showToast && utils.showErrorToast(error);
    throw error;
};
