import {errorsCodes} from 'common';
import {toast} from 'react-toastify';
import {CartItem} from 'components';
import React from 'react';

export const utils = {
    getErrorString: (error) => {
        let textError = '';
        const arr = Object.values(error.response.data).flat();
        for (let i = 0; i < arr.length; i += 1) {
            textError += `${arr[i]}\n`;
        }
        return textError;
    },
    showErrorToast: (error) => {
        if (error.response?.status === errorsCodes.SERVER_ERROR) {
            toast.error(error.response.statusText);
        }

        if (error.response?.data) {
            toast.error(utils.getErrorString(error));
        } else {
            toast.error(error.message ? error.message : error);
        }
    },
    toArrayMapping: (data, key) => {
        let result = {};
        data.forEach((item) => {
            const objKey = item[key].id
            objKey in result ? result[objKey].push(item) : result[objKey] = [item];
        });
        return result
    },
    toArrayMappingById: (data) => {
        return data.reduce((dict, item) => {
            dict[item.id] = item;
            return dict;
        }, {});
    },
    to12HourFormat: (time) => {
        const dateObject = new Date("1970-01-01T" + time + "Z");
        if (isNaN(dateObject.getTime())) {
            return null;
        }
        return dateObject.toLocaleTimeString("en-US", {
            timeZone: "UTC",
            hour12: true,
            hour: "2-digit",
            minute: "numeric",
        });
    },
    scrollWindowToTop: () => {
        window.scroll({top: 0});
    },
    snakeCaseToTitleCase: (str) => {
        const words = str.split('_');
        const titleCaseWords = words.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        return titleCaseWords.join(' ');
    },
    getParamFromUrl: (url, key) => {
        if (!url) {
            return null
        }
        const urlObj = new URL(url);
        const pageParam = urlObj.searchParams.get(key);
        return pageParam ? parseInt(pageParam) : null;
    },
    formatPrice: (price) => {
        return `Rs. ${price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || ''}`;
    },
    calculateTotalPrice: (cartProducts) => {
        let totalPrice = 0;
        for (const productId in cartProducts) {
            if (cartProducts.hasOwnProperty(productId)) {
                const cartItem = cartProducts[productId];
                const {price, quantity} = cartItem;
                totalPrice += price * quantity;
            }
        }
        return utils.formatPrice(totalPrice);
    },
    createOrderProducts: (cartProducts) => {
        return Object.keys(cartProducts).map((productId, index) => {
            return {
                product: productId,
                quantity: cartProducts[productId].quantity,
            }
        });
    },
};
