import React, {useEffect, useState} from 'react';
import 'pages/detail/_detail.scss';
import {AnimatedButton1, Carousel, CustomCollapse, CustomerReviews, CustomRating, ProductSection} from 'components';
import {labels} from 'common/constants';
import {useDispatch, useSelector} from 'react-redux';
import {generalExtraActions} from 'reduxStore/actions';
import {hooks, routes, utils} from 'common';
import {useParams} from 'react-router-dom';
import {api} from 'api';
import {cartActions} from 'reduxStore/reducers/cartSlice';
import {useCartContext} from 'contexts';

const variants = [
    '40', '41', '42', '43', '44', '45', '46'
]

function Detail() {
    const {id: productId} = useParams();
    const dispatch = useDispatch();
    const {displayCart} = useCartContext();
    const {topProducts} = useSelector(state => state.general);
    const [product, setProduct] = useState({});

    const handleAddToCart = () => {
        dispatch(cartActions.addProduct(
            {
                productId: product?.id,
                product: {
                    name: product?.name,
                    price: product?.price,
                    quantity: 1,
                    image_url: product?.image_urls[0],
                }
            }
        ));
        displayCart();
    }

    const fetchData = async () => {
        setProduct(await api.productDetail(productId));
    };

    useEffect(() => {
        if (topProducts.length === 0) {
            dispatch(generalExtraActions.topProducts());
        }
        fetchData();
    }, []);

    hooks.useScrollToTopInstant();
    return (
        <div className={'detail'}>
            <div className={'product-detail'}>
                <Carousel images={product?.image_urls}/>
                <div className={'detail-section'}>
                    <div className={'main-detail'}>
                        <CustomRating value={5}/>
                        <span className={'name'}>{product?.name}</span>
                        {/*<span className={'color'}>Black</span>*/}
                        <span className={'price'}>{utils.formatPrice(product?.price)}</span>
                    </div>
                    {/*<div className={'variant-section'}>*/}
                    {/*    <span className={'v-head'}>{labels.SELECT_SIZE}</span>*/}
                    {/*    <div className={'variants'}>*/}
                    {/*        {variants.map((variant, index) => {*/}
                    {/*            return <span>{variant}</span>*/}
                    {/*        })}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={'atc-section'}>
                        <AnimatedButton1 title={labels.ADD_TO_CART} onClick={handleAddToCart}/>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <CustomCollapse title={'DESCRIPTION'} description={product?.description} defaultOpen={true}/>
                    </div>
                </div>
            </div>
            {/*<CustomerReviews reviews={[]}/>*/}
            <ProductSection heading={labels.BEST_SELLER} viewAllLink={routes.SHOP} products={topProducts}/>
        </div>
    );
}

export default Detail;