import React, {useEffect, useState} from 'react';
import 'components/carousel/_carousel.scss';

const CustomCarousel = ({images}) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    useEffect(() => {
        if (images?.length) {
            setSelectedImage(images[0]);
        }
    }, [images]);

    return (
        <div className={'carousel'}>
            <div className={'thumbnail-container'}>
                {images?.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Thumbnail ${index}`}
                        onClick={() => handleImageClick(image)}
                        className={`thumbnail ${selectedImage === image ? 'active' : ''}`}
                    />
                ))}
            </div>
            <div className={'main-image-container'}>
                <img src={selectedImage} alt="Main Image" className={'main-image'}/>
            </div>
        </div>
    );
};

export default CustomCarousel;
