import '_app.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AboutUs, Checkout, ContactUs, Detail, ExchangePolicy, Home, Shop} from 'pages';
import {Layout} from 'components';
import {globals, routes} from 'common';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route index path={routes.HOME} element={<Home/>}></Route>
                    <Route index path={routes.DETAIL} element={<Detail/>}></Route>
                    <Route index path={routes.CHECKOUT} element={<Checkout/>}></Route>
                    <Route index path={routes.SHOP} element={<Shop/>}></Route>
                    <Route index path={routes.ABOUT_US} element={<AboutUs/>}></Route>
                    <Route index path={routes.RETURN_N_EXCHANGE_POLICY} element={<ExchangePolicy/>}></Route>
                    <Route index path={routes.CONTACT_US} element={<ContactUs/>}></Route>
                </Route>
            </Routes>
            <ToastContainer autoClose={globals.TOAST_TIMER}/>
        </BrowserRouter>
    );
}

export default App;
