import {API_BASE_URL} from 'config';

const CORE_V1 = `${API_BASE_URL}/core/api/v1`;

export const endpoints = {
    TOP_PRODUCTS: `${CORE_V1}/products/top_products/`,
    ALL_PRODUCTS: `${CORE_V1}/products/`,
    PRODUCT_DETAIL: id => `${CORE_V1}/products/${id}/`,
    ORDER_CREATE: `${CORE_V1}/orders/`,
    ORDER_UPDATE: id => `${CORE_V1}/orders/${id}/`,
    ORDER_CHECKOUT: id => `${CORE_V1}/orders/${id}/checkout/`,
    REVIEW_CREATE: `${CORE_V1}/products-reviews/`,
    CONTACT_US: `${CORE_V1}/contact-us/`,
};
