import React from 'react';
import {Button} from 'react-bootstrap';
import 'components/animatedLink/_animatedLink.scss'

function AnimatedButton1({title, onClick = () => {}, type=''}) {
    return (
        <Button type={type} className={'animated-button-1'} onClick={onClick}>{title}</Button>
    );
}

export default AnimatedButton1;