import axios from 'axios';
import { localStore } from 'common';
import { handleError } from 'api/helpers';

const getConfig = (withToken, token = null) => {
  return withToken ? {headers: {Authorization: token ? `customer_token ${token}` : `Bearer ${localStore.getAccessToken()}`}} : {};
};

export const apiUtils = {
  get: async (url, params = {}, withToken = true, token = null) => {
    const config = getConfig(withToken, token);
    config.params = params;
    try {
      return (await axios.get(url, config)).data;
    } catch (error) {
      return await handleError(error, apiUtils.get, url, params, withToken);
    }
  },

  post: async (url, data = {}, withToken = true, token) => {
    try {
      const config = getConfig(withToken, token);
      return (await axios.post(url, data, config)).data;
    } catch (error) {
      return await handleError(error, apiUtils.post, url, data, withToken);
    }
  },
  put: async (url, data, withToken = true) => {
    try {
      const config = getConfig(withToken);
      return (await axios.put(url, data, config)).data;
    } catch (error) {
      return await handleError(error, this, url, data, withToken);
    }
  },
  patch: async (url, data, withToken = true, showToast=true) => {
    try {
      const config = getConfig(withToken);
      return (await axios.patch(url, data, config)).data;
    } catch (error) {
      return await handleError(error, this, url, data, withToken, showToast);
    }
  },
  delete: async (url, data={}, withToken = true) => {
    try {
      const config = getConfig(withToken);
      return (await axios.delete(url, config)).data;
    } catch (error) {
      return await handleError(error, this, url, data, withToken);
    }
  },
};
