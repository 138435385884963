import React from 'react';
import {siteDate, labels, sentences} from 'common/constants';
import {Link} from 'react-router-dom';
import 'components/footer/_footer.scss';
import {BsFacebook, BsInstagram, BsLinkedin, BsThreads, BsTwitter} from 'react-icons/bs';
import {COD, EasyPaisa, JazzCash, MasterCard, OnlineBanking, VISA} from 'assets';
import {routes} from 'common';

function Footer(props) {
    return (<div className={'footer'}>
        <div className={'row row1'}>
            <div className={'col'}>
                <h3 className={'heading'}>{labels.INFORMATION}</h3>
                <div className={'content'}>
                    <Link to={routes.CONTACT_US}>{labels.CONTACT_US}</Link>
                    {/*<Link to={''}>{labels.SHIPPING_POLICY}</Link>*/}
                    <Link to={routes.RETURN_N_EXCHANGE_POLICY}>{labels.RETURN_N_EXCHANGE_POLICY}</Link>
                </div>
            </div>
            <div className={'col'}>
                <h3 className={'heading'}>{labels.CONTACT}</h3>
                <div className={'content'}>
                    <span>{labels.HOURS}</span>
                    <p>{siteDate.HOURS}</p>
                    <span>{labels.PHONE}</span>
                    <p>{siteDate.PHONE}</p>
                    <span>{labels.EMAIL}</span>
                    <p>{siteDate.EMAIL}</p>
                </div>
            </div>
        </div>
        <div className={'row'}>
            <div className={'colRL'}>
                <img src={COD} alt={''}/>
            </div>
            <div className={'colRR'}>
                <Link to={siteDate.INSTAGRAM} target="_blank"><BsInstagram size={25}></BsInstagram></Link>
            </div>
        </div>
        <div className={'row row3'}>
            <p>© {(new Date()).getFullYear()} | {siteDate.BRAND_NAME} | {siteDate.COUNTRY} | All rights reserved </p>
        </div>
    </div>);
}

export default Footer;
