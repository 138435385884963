import React, {createContext, useContext, useState} from 'react';
import {Cart} from 'components';

const CartContext = createContext();

export const CartProvider = ({children}) => {
    const [showCart, setShowCart] = useState(false);

    return (
        <CartContext.Provider
            value={{
                displayCart: () => setShowCart(true)
            }}
        >
            {children}
            <Cart show={showCart} setShow={setShowCart}/>
        </CartContext.Provider>
    );
};

export const useCartContext = () => {
    return useContext(CartContext);
};
