import React, {useState} from 'react';
import 'components/header/_header.scss';
import {labels, routes} from 'common';
import {AnimatedLink1, SearchBar} from 'components';
import {SlHandbag, SlBag} from "react-icons/sl";
import {Logo, Logo2} from 'assets';
import {useCartContext} from 'contexts';
import {BsBag, BsBagFill} from 'react-icons/bs';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';


function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const {displayCart} = useCartContext();
    const {cartProducts} = useSelector(state => state.cart);
    const [showSearchBar, setShowSearchBar] = useState(false);

    return (
        <>
            <div className={'header'}>
                <div className={'left-part'}>
                    <img onClick={() => navigate(routes.HOME)} className={'logo'} src={Logo2} alt={'logo'}/>
                </div>
                <div className={'center-part'}>
                    <AnimatedLink1 link={routes.HOME} label={labels.HOME}/>
                    <AnimatedLink1 link={routes.SHOP} label={labels.SHOP}/>
                    <AnimatedLink1 link={routes.CONTACT_US} label={labels.CONTACT_US}/>
                    {/*<AnimatedLink1 link={routes.ABOUT_US} label={labels.ABOUT_US}/>*/}
                </div>
                <div className={'right-part'}>
                    {/*<button onClick={() => setShowSearchBar(!showSearchBar)}><HiOutlineSearch size={25}/></button>*/}
                    {/*<button><LiaUserSolid size={30}/></button>*/}
                    {location.pathname !== routes.CHECKOUT && <button onClick={() => displayCart()}>
                        {Object.keys(cartProducts).length > 0 ? <BsBagFill size={25}/> : <BsBag size={25}/>}
                    </button>}
                </div>
            </div>
            {/*<SearchBar show={showSearchBar} closeBar={() => setShowSearchBar(false)}/>*/}
        </>);
}

export default Header;
