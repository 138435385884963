import {createSlice} from '@reduxjs/toolkit';
import {globals} from 'common';
import {localStore} from 'common/localStore';

const INITIAL_STATE = {
    cartProducts: localStore.getCartProducts(),
    loading: false,
    error: null,
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState: INITIAL_STATE,
    reducers: {
        reset: () => {
            return {
                cartProducts: {},
                loading: false,
                error: null,
            }
        },
        addProduct: (state, {payload}) => {
            const {productId, product} = payload;
            const updatedCartProducts = {...state.cartProducts};
            if (updatedCartProducts.hasOwnProperty(productId)) {
                const quantity = updatedCartProducts[productId].quantity;
                if (quantity < globals.MAX_PRODUCT_QUANTITY) {
                    updatedCartProducts[productId].quantity = quantity + 1;
                }
            } else {
                updatedCartProducts[productId] = product;
            }
            state.cartProducts = updatedCartProducts;
            localStore.storeCartProducts(state.cartProducts);
        },
        increaseProductQuantity: (state, {payload: productId}) => {
            const updatedCartProducts = {...state.cartProducts};
            const quantity = updatedCartProducts[productId].quantity;
            if (quantity < globals.MAX_PRODUCT_QUANTITY) {
                updatedCartProducts[productId].quantity = quantity + 1;
            }
            state.cartProducts = updatedCartProducts;
            localStore.storeCartProducts(updatedCartProducts);
        },
        decreaseProductQuantity: (state, {payload: productId}) => {
            const updatedCartProducts = {...state.cartProducts};
            const quantity = updatedCartProducts[productId].quantity;
            if (quantity > globals.MIN_PRODUCT_QUANTITY) {
                updatedCartProducts[productId].quantity = quantity - 1;
            }
            state.cartProducts = updatedCartProducts;
            localStore.storeCartProducts(updatedCartProducts);
        },
        removeProduct: (state, {payload: productId}) => {
            const updatedCartProducts = {...state.cartProducts};
            if (updatedCartProducts.hasOwnProperty(productId)) {
                delete updatedCartProducts[productId];
            }
            state.cartProducts = updatedCartProducts;
            localStore.storeCartProducts(updatedCartProducts);
        }
    },
    extraReducers: (builder) => {
    },
});

export const {actions: cartActions, reducer} = cartSlice;
export default reducer;
