import React, {useEffect, useState} from 'react';
import 'components/bgSection/_bgSection.scss';
import {AnimatedLink2} from 'components';
import {globals} from 'common';

function BgSection({smallText, largeText, link, btnLabel, bgImg, bgSmallImg}) {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= globals.BG_WIDTH_BREAKPOINT);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={'bg-section'}>
            {bgImg && bgSmallImg && <img className={'bg-img'} src={isSmallScreen ? bgSmallImg : bgImg} alt={'bg'}/>}
            {/*<div className={'content'}>*/}
            {/*    {smallText && <span className={'small-text'}>{smallText}</span>}*/}
            {/*    {largeText && <span className={'large-text'}>{largeText}</span>}*/}
            {/*    {link && <AnimatedLink2 link={link} label={btnLabel}/>}*/}
            {/*</div>*/}
        </div>
    );
}

export default BgSection;