import {createSlice} from '@reduxjs/toolkit';
import {localStore} from 'common';

const INITIAL_STATE = {
    user: localStore.getUser(),
    allUsers: [],
    allUsersMap: {},
    loading: false,
    isUserLoggedIn: localStore.isUserLoggedIn(),
    error: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        reset: () => INITIAL_STATE,
        logout: (state,) => {
            state.isUserLoggedIn = false;
            state.token = {
                access: '',
                refresh: '',
            }
            localStore.clearLocalStorge();
        }
    },
    extraReducers: (builder) => {
    },
});

export const {actions: authActions, reducer} = authSlice;
export default reducer;
