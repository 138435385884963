import React from 'react';
import Optin from 'components/optin/Optin';
import Preloader from 'components/preloader/Preloader';
import Timer from 'components/timer/TImer';

function CustomComingSoon(props) {
    return (
        <div className="App">
            <div className="container d-flex flex-column gap-2">
                <h1>
                    The Magic Line
                </h1>
                <h3 className="mb-5">
                    <b>Coming Soon</b>
                </h3>
                <Timer/>
                {/*<Optin/>*/}
                {/*<Preloader/>*/}
            </div>
        </div>
    );
}

export default CustomComingSoon;