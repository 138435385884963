export const tokenKeys = {
    ACCESS: 'access',
    REFRESH: 'refresh',
};

export const localStore = {
    storeTokens: (tokens) => {
        localStorage.setItem(tokenKeys.ACCESS, tokens.access);
        localStorage.setItem(tokenKeys.REFRESH, tokens.refresh);
    },
    storeAccessToken: (access_token) => {
        localStorage.setItem(tokenKeys.ACCESS, access_token);
    },
    getAccessToken: () => {
        return localStorage.getItem(tokenKeys.ACCESS);
    },
    getRefreshToken: () => {
        return localStorage.getItem(tokenKeys.REFRESH);
    },
    clearLocalStorge: () => {
        localStorage.clear();
    },
    storeUser: (user) => {
        localStorage.setItem('user', JSON.stringify(user));
    },
    getUser: () => {
        return JSON.parse(localStorage.getItem('user'));
    },
    isUserLoggedIn: () => {
        return !!localStore.getAccessToken() && !!localStore.getUser();
    },
    storeCartProducts: (cartProducts) => {
        localStorage.setItem('cart_products', JSON.stringify(cartProducts));
    },
    getCartProducts: () => {
        const cartProducts = localStorage.getItem('cart_products');
        return cartProducts ? JSON.parse(cartProducts) : {};
    },
    removeCartProducts: () => {
        localStorage.removeItem('cart_products');
    },
    storeOrder: (order) => {
        localStorage.setItem('order', JSON.stringify(order));
    },
    getOrder: () => {
        const order = localStorage.getItem('order');
        return order ? JSON.parse(order) : null;
    },
    removeOrder: () => {
        localStorage.removeItem('order');
    },
};
